<template>
    <div className="card-body">
        <div className="card-body-title">Добавить оплату</div>
        <form className="payment-create row flex" v-on:submit.prevent>
            <div className="col-4">
                <label className="form-label">
                    <div className="t">Заголовок *</div>
                    <input v-model="form.title" placeholder="Заголовок *" required/>
                </label>
            </div>
            <div className="col-4">
                <label className="form-label">
                    <div className="t">Стадия *</div>
                    <select v-model="form.status_id">
                        <option v-for="status in statuses" :value="status.id">{{status.title}}</option>
                    </select>
                </label>
            </div>
            <div className="col-4">
                <label className="form-label">
                    <div className="t">Дедлайн стадии *</div>
                    <input v-model="form.status_dl" placeholder="Дедлайн" type="date" required/>
                </label>
            </div>
            <div className="col-3">
                <label className="form-label">
                    <div className="t">Пользователь *</div>
                    <!--
                    <select v-model="form.user_id">
                        <option v-for="user in users" :value="user.id">{{user.name}}</option>
                    </select>
                    -->
                    <Select2 v-model="form.user_id"
                             :options="users.map(item => {item.text = item.name; return item;})"
                             :settings="{width: '100%'}"/>
                </label>
            </div>
            <div className="col-3">
                <label className="form-label">
                    <div className="t">Направление оплаты *</div>
                    <select v-model="form.direction_id">
                        <option v-for="dir in directions" :value="dir.id">{{dir.title}}</option>
                    </select>
                </label>
            </div>
            <div className="col-3">
                <label className="form-label">
                    <div className="t">Тип оплаты *</div>
                    <select v-model="form.type_id">
                        <option v-for="type in types" :value="type.id">{{type.title}}</option>
                    </select>
                </label>
            </div>
            <div className="col-3">
                <label className="form-label">
                    <div className="t">Сумма *</div>
                    <input v-model="form.amount" placeholder="Сумма" required type="number" min="0" step="1"/>
                </label>
            </div>
            <div className="col-4">
                <button :disabled="!btnEnabled" class="btn blue inb" @click="create">Добавить</button>
            </div>
        </form>
    </div>
</template>

<script>
import Select2 from 'vue3-select2-component';
export default {
    name: "paymentCreate",
    components: {Select2},
    props: [
        'parent_id',
    ],
    data() {
        return {
            loading:false,
            form: {},
            users:[],
            statuses:[],
            directions:[],
            types:[],
        }
    },
    methods: {
        create() {
            this.loading = true;
            this.$api.post(`claim/${this.$props.parent_id}/payment`, this.form).then(res => {
                this.loading = false;
                if (res.data.success) {
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Оплата успешно создана',
                        type: 'success'
                    });
                    this.form.title = '';
                    this.form.amount = 0;
                    this.$emit('uploaded');
                    this.$emit('updated');
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getUsers() {
            this.$api.get(`user`, this.form).then(res => {
                if (res.data.success) {
                    this.users = res.data.data;
                    this.form.user_id = 0;
                }
            })
        },
        getStatuses() {
            this.$api.get(`payment/stages`, this.form).then(res => {
                if (res.data.success) {
                    this.statuses = res.data.data;
                    this.form.status_id = 1;
                }
            })
        },
        getDirections() {
            this.$api.get(`payment/directions`, this.form).then(res => {
                if (res.data.success) {
                    this.directions = res.data.data;
                    this.form.direction_id = 1;
                }
            })
        },
        getTypes() {
            this.$api.get(`payment/types`, this.form).then(res => {
                if (res.data.success) {
                    this.types = res.data.data;
                    this.form.type_id = 1;
                }
            })
        },
    },
    created() {
        this.getUsers();
        this.getStatuses();
        this.getDirections();
        this.getTypes();
    },
    computed: {
        btnEnabled: function () {
            return this.form.title && this.form.status_dl && this.form.amount && this.form.user_id != 0;
        }
    }
}
</script>

<style scoped lang="scss">
.payment-create {
    .form-label {
        margin: 0 0 20px;
    }
}
</style>